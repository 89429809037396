/* styles.CSS 4.15 December 2020 by Jan Egil and Borge Refsnes */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* Extract from normalize.css by Nicolas Gallagher and Jonathan Neal git.io/normalize */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

@keyframes moveBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
summary {
  display: list-item;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
progress {
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
figure {
  margin: 1em 40px;
}
img {
  border-style: none;
}
code,
kbd,
pre,
samp {
  font-family: sans-serif;
  font-size: 1em;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
select,
textarea,
optgroup {
  font: inherit;
}
optgroup {
  font-weight: bold;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
/* End extract */
html,
body {
  font-family: sans-serif;
  font-size: 15px;
  height: 100%;
  line-height: 1.8;
  animation: moveBackgroun 10s infinite linear;
  /* overflow-x: hidden; */
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
.styles-serif {
  font-family: serif;
}
.styles-sans-serif {
  font-family: sans-serif;
}
.styles-cursive {
  font-family: cursive;
}
.styles-monospace {
  font-family: monospace;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Segoe UI", Arial, sans-serif;
  font-weight: 400;
  margin: 10px 0;
}
.styles-wide {
  letter-spacing: 4px;
}
hr {
  border: 0;
  border-top: 1px solid #eee;
  margin: 20px 0;
}
.styles-image {
  max-width: 100%;
  height: auto;
}
img {
  vertical-align: middle;
}
a {
  color: inherit;
}
.styles-table,
.styles-table-all {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;
  border: 1px solid #0b6c8c;
}
.styles-table-all {
  border: 1px solid #0b6c8c;
}
.styles-bordered tr,
.styles-table-all tr {
  border-bottom: 1px solid #0b6c8c;
}
.styles-striped tbody tr:nth-child(even) {
  background-color: lightgrey;
}
.styles-table-all tr:nth-child(odd) {
  background-color: whitesmoke;
}
.styles-table-all tr:nth-child(even) {
  background-color: lightgrey;
}
.styles-hoverable tbody tr:hover,
.styles-ul.styles-hoverable li:hover {
  background-color: #ccc;
}
.styles-centered tr th,
.styles-centered tr td {
  text-align: center;
}
.styles-table td,
.styles-table th,
.styles-table-all td,
.styles-table-all th {
  padding: 8px 8px;
  display: table-cell;
  text-align: left;
  vertical-align: top;
}
.styles-table th:first-child,
.styles-table td:first-child,
.styles-table-all th:first-child,
.styles-table-all td:first-child {
  padding-left: 16px;
}
.styles-btn,
.styles-button {
  border: none;
  display: inline-block;
  padding: 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: white;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 12px;
}
.styles-btn:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.styles-btn,
.styles-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.styles-disabled,
.styles-btn:disabled,
.styles-button:disabled {
  cursor: not-allowed;
  opacity: 0.2;
}
.styles-disabled *,
:disabled * {
  pointer-events: none;
}
.styles-btn.styles-disabled:hover,
.styles-btn:disabled:hover {
  box-shadow: none;
}
.styles-disabled-text {
  opacity: 2;
}
.styles-badge,
.styles-tag {
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}
.styles-badge {
  border-radius: 50%;
}
.styles-ul {
  list-style-type: none;
  padding: 0;
  margin: auto;
}
.styles-ul li {
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
}
.styles-ul li:last-child {
  border-bottom: none;
}
.styles-tooltip,
.styles-display-container {
  position: relative;
}
.styles-tooltip .styles-text {
  display: none;
}
.styles-tooltip:hover .styles-text {
  display: inline-block;
}
.styles-ripple:active {
  opacity: 0.5;
}
.styles-ripple {
  transition: opacity 0s;
}
.styles-input {
  padding: 8px;
  display: block;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 50%;
}
.styles-select {
  padding: 9px 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid white;
}
.styles-dropdown-click,
.styles-dropdown-hover {
  position: relative;
  display: block;
  cursor: pointer;
  float: left;
}
.styles-dropdown-hover:hover .styles-dropdown-content {
  display: none;
  float: left;
}
.styles-dropdown-hover:first-child,
.styles-dropdown-click:hover {
  background-color: inherit;
  border: none;
  color: #051336;
  outline: none;
  font-family: inherit;
  margin: auto;
  overflow: hidden;
  float: left;
}
.styles-dropdown-hover:hover > .styles-button:first-child,
.styles-dropdown-click:hover > .styles-button:first-child {
  background-color: inherit;
  border: none;
  color: #051336;
  outline: none;
  font-family: inherit;
  margin: auto;
  overflow: hidden;
}
.styles-dropdown-content {
  cursor: auto;
  color: #000;
  background-color: #fff;
  display: none;
  position: absolute;
  min-width: 160px;
  margin: auto;
  padding: 0;
  z-index: 1;
}

.styles-check,
.styles-radio {
  width: 24px;
  height: 24px;
  position: relative;
  top: 6px;
}
.styles-sidebar {
  height: 100%;
  width: 0;
  right: 0;
  background-color: #fff;
  position: fixed !important;
  z-index: 1;
  overflow: auto;
  transition: 0.5s;
}
.styles-bar-block .styles-dropdown-hover,
.styles-bar-block .styles-dropdown-click {
  width: 100%;
}
.styles-bar-block .styles-dropdown-hover .styles-dropdown-content,
.styles-bar-block .styles-dropdown-click .styles-dropdown-content {
  min-width: 100%;
}
.styles-bar-block .styles-dropdown-hover .styles-button,
.styles-bar-block .styles-dropdown-click .styles-button {
  width: 100%;
  text-align: left;
  padding: 4px 8px;
}
.styles-main,
#main {
  transition: margin-left 0.4s;
}
.styles-modal {
  z-index: 3;
  display: none;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.styles-modal-content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 600px;
}
.styles-bar {
  width: 100%;
  overflow: hidden;
  margin: auto;
  padding: 0;
  position: fixed;
  top: 0;
  list-style-type: none;
}
.styles-center .styles-bar {
  display: inline-block;
  width: auto;
}
.styles-bar .styles-bar-item {
  padding: 8px 16px;
  float: left;
  width: auto;
  border: none;
  display: block;
  outline: 0;
  overflow: hidden;
}

.styles-bar .styles-dropdown-hover,
.styles-bar .styles-dropdown-click {
  position: fixed;
  float: right;
}
.styles-bar .styles-button {
  white-space: normal;
}
.styles-bar-block .styles-bar-item {
  width: 100%;
  display: block;
  padding: 8px 16px;
  text-align: right;
  border: none;
  white-space: normal;
  float: none;
  outline: 0;
}
.styles-bar-block.styles-center .styles-bar-item {
  text-align: center;
}
.styles-block {
  display: block;
  width: 100%;
}
.styles-responsive {
  display: block;
  overflow-x: auto;
}

.styles-container:after,
.styles-container:before,
.styles-panel:after,
.styles-panel:before,
.styles-row:after,
.styles-row:before,
.styles-row-padding:after,
.styles-row-padding:before,
.styles-cell-row:before,
.styles-cell-row:after,
.styles-clear:after,
.styles-clear:before,
.styles-bar:before,
.styles-bar:after {
  content: "";
  display: table;
  clear: both;
}
.styles-col,
.styles-half,
.styles-third,
.styles-twothird,
.styles-threequarter,
.styles-quarter {
  float: left;
  width: 100%;
}
.styles-col.s1 {
  width: 8.33333%;
}
.styles-col.s2 {
  width: 16.66666%;
}
.styles-col.s3 {
  width: 24.99999%;
}
.styles-col.s4 {
  width: 33.33333%;
}
.styles-col.s5 {
  width: 41.66666%;
}
.styles-col.s6 {
  width: 49.99999%;
}
.styles-col.s7 {
  width: 58.33333%;
}
.styles-col.s8 {
  width: 66.66666%;
}
.styles-col.s9 {
  width: 74.99999%;
}
.styles-col.s10 {
  width: 83.33333%;
}
.styles-col.s11 {
  width: 91.66666%;
}
.styles-col.s12 {
  width: 99.99999%;
}
@media (min-width: 601px) {
  .styles-col.m1 {
    width: 8.33333%;
  }
  .styles-col.m2 {
    width: 16.66666%;
  }
  .styles-col.m3,
  .styles-quarter {
    width: 24.99999%;
  }
  .styles-col.m4,
  .styles-third {
    width: 33.33333%;
  }
  .styles-col.m5 {
    width: 41.66666%;
  }
  .styles-col.m6,
  .styles-half {
    width: 49.99999%;
  }
  .styles-col.m7 {
    width: 58.33333%;
  }
  .styles-col.m8,
  .styles-twothird {
    width: 66.66666%;
  }
  .styles-col.m9,
  .styles-threequarter {
    width: 74.99999%;
  }
  .styles-col.m10 {
    width: 83.33333%;
  }
  .styles-col.m11 {
    width: 91.66666%;
  }
  .styles-col.m12 {
    width: 99.99999%;
  }
}
@media (min-width: 993px) {
  .styles-col.l1 {
    width: 8.33333%;
  }
  .styles-col.l2 {
    width: 16.66666%;
  }
  .styles-col.l3 {
    width: 24.99999%;
  }
  .styles-col.l4 {
    width: 33.33333%;
  }
  .styles-col.l5 {
    width: 41.66666%;
  }
  .styles-col.l6 {
    width: 49.99999%;
  }
  .styles-col.l7 {
    width: 58.33333%;
  }
  .styles-col.l8 {
    width: 66.66666%;
  }
  .styles-col.l9 {
    width: 74.99999%;
  }
  .styles-col.l10 {
    width: 83.33333%;
  }
  .styles-col.l11 {
    width: 91.66666%;
  }
  .styles-col.l12 {
    width: 99.99999%;
  }
}

.styles-rest {
  overflow: hidden;
}
.styles-stretch {
  margin-left: -16px;
  margin-right: -16px;
}
.styles-content,
.styles-auto {
  margin-left: auto;
  margin-right: auto;
}
.styles-content {
  max-width: 980px;
}
.styles-auto {
  max-width: 1140px;
}
.styles-cell-row {
  display: table;
  width: 100%;
  border-radius: 25px;
}
.styles-cell {
  display: table-cell;
  border-radius: 25px;
}
.styles-cell-top {
  vertical-align: top;
}
.styles-cell-middle {
  vertical-align: middle;
}
.styles-cell-bottom {
  vertical-align: bottom;
}
.styles-hide {
  display: none !important;
}
.styles-show-block,
.styles-show {
  display: block !important;
}
.styles-show-inline-block {
  display: inline-block !important;
}
@media (max-width: 1205px) {
  .styles-auto {
    max-width: 95%;
  }
}
@media (max-width: 600px) {
  .styles-modal-content {
    margin: 0 10px;
    width: auto !important;
  }
  .styles-modal {
    padding-top: 30px;
  }
  .styles-dropdown-hover.styles-mobile .styles-dropdown-content,
  .styles-dropdown-click.styles-mobile .styles-dropdown-content {
    position: relative;
  }
  .styles-hide-small {
    display: none !important;
  }
  .styles-mobile {
    display: block;
    width: 100% !important;
  }
  .styles-bar-item.styles-mobile,
  .styles-dropdown-hover.styles-mobile,
  .styles-dropdown-click.styles-mobile {
    text-align: center;
  }
  .styles-dropdown-hover.styles-mobile,
  .styles-dropdown-hover.styles-mobile .styles-btn,
  .styles-dropdown-hover.styles-mobile .styles-button,
  .styles-dropdown-click.styles-mobile,
  .styles-dropdown-click.styles-mobile .styles-btn,
  .styles-dropdown-click.styles-mobile .styles-button {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .styles-modal-content {
    width: 500px;
  }
  .styles-modal {
    padding-top: 50px;
  }
}
@media (min-width: 993px) {
  .styles-modal-content {
    width: 900px;
  }
  .styles-hide-large {
    display: none !important;
  }
  .styles-sidebar.styles-collapse {
    display: block !important;
  }
}
@media (max-width: 992px) and (min-width: 601px) {
  .styles-hide-medium {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .styles-sidebar.styles-collapse {
    display: none;
  }
  .styles-main {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .styles-auto {
    max-width: 100%;
  }
}
.styles-top,
.styles-bottom {
  position: fixed;
  width: 100%;
  z-index: 1;
}
.styles-top {
  top: 0;
}
.styles-bottom {
  bottom: 0;
}
.styles-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.styles-display-topright {
  position: absolute;
  right: 0;
  top: 0;
}
.styles-display-bottomleft {
  position: absolute;
  left: 0;
  bottom: 0;
}

.styles-display-left {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  -ms-transform: translate(-0%, -50%);
}

.styles-display-container:hover .styles-display-hover {
  display: block;
}
.styles-display-container:hover span.styles-display-hover {
  display: inline-block;
}
.styles-display-hover {
  display: none;
}
.styles-display-position {
  position: absolute;
}

.styles-row-padding,
.styles-row-padding > .styles-half,
.styles-row-padding > .styles-third,
.styles-row-padding > .styles-twothird,
.styles-row-padding > .styles-threequarter,
.styles-row-padding > .styles-quarter,
.styles-row-padding > .styles-col {
  padding: 0 8px;
  border-radius: 25px;
}
.styles-container,
.styles-panel {
  padding: 0.01em 16px;
}

.styles-code,
.styles-codespan {
  font-family: Consolas, "courier new";
  font-size: 16px;
}

.styles-card,
.styles-card-2 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.styles-card-4,
.styles-hover-shadow:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
}

.styles-animate-left {
  position: relative;
  animation: animateleft 0.4s;
}
.styles-animate-right {
  position: relative;
  animation: animateright 0.4s;
}
@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

.styles-animate-zoom {
  animation: animatezoom 0.6s;
}
@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.styles-greyscale-max,
.styles-grayscale-max,
.styles-hover-greyscale:hover,
.styles-hover-grayscale:hover {
  filter: grayscale(100%);
}
.styles-greyscale,
.styles-grayscale {
  filter: grayscale(75%);
}
.styles-greyscale-min,
.styles-grayscale-min {
  filter: grayscale(50%);
}
.styles-medium {
  font-size: 14px !important;
}
.styles-large {
  font-size: 18px !important;
}
.styles-xlarge {
  font-size: 24px !important;
}
.styles-xxlarge {
  font-size: 36px !important;
}

.styles-jumbo {
  font-size: 64px !important;
}

.styles-center {
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.styles-border {
  border: 1px solid #ccc !important;
  position: center;
}

.styles-section,
.styles-code {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.styles-margin {
  margin: 16px !important;
  position: center;
}
.styles-margin-top {
  margin-top: 16px !important;
}
.styles-margin-bottom {
  margin-bottom: 16px !important;
}
.styles-margin-left {
  margin-left: 16px !important;
}
.styles-margin-right {
  margin-right: 16px !important;
}
.styles-padding-small {
  padding: 4px 8px !important;
}
.styles-padding {
  padding: 8px 16px !important;
}
.styles-padding-large {
  padding: 12px 24px !important;
}
.styles-padding-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.styles-padding-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.styles-padding-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
.styles-padding-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
.styles-padding-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}
.styles-padding-105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

.styles-left {
  float: left !important;
}
.styles-right {
  float: right !important;
}
.styles-button:hover {
  color: #000 !important;
  background-color: #fff !important;
}
.styles-transparent,
.styles-hover-none:hover {
  background-color: transparent !important;
}
.styles-hover-none:hover {
  box-shadow: none !important;
}
/* Colors */
.styles-red,
.styles-hover-red:hover {
  color: #fff !important;
  background-color: #f44336 !important;
}
.styles-white,
.styles-hover-white:hover {
  color: #000 !important;
  background-color: #fff !important;
}
.styles-black,
.styles-hover-black:hover {
  color: #fff !important;
  background-color: #000 !important;
}
.styles-grey,
.styles-hover-grey:hover,
.styles-gray,
.styles-hover-gray:hover {
  color: #000 !important;
  background-color: #9e9e9e !important;
}
.styles-light-grey,
.styles-hover-light-grey:hover,
.styles-light-gray,
.styles-hover-light-gray:hover {
  color: #000 !important;
  background-color: #f1f1f1 !important;
}
.styles-dark-grey,
.styles-hover-dark-grey:hover,
.styles-dark-gray,
.styles-hover-dark-gray:hover {
  color: #fff !important;
  background-color: #616161 !important;
}
.styles-text-navy,
.styles-hover-text-navy:hover {
  color: #051336 !important;
}
.styles-text-gold,
.styles-hover-text-gold:hover {
  color: #e0aa3e !important;
}
.styles-text-white,
.styles-hover-text-white:hover {
  color: #fff !important;
}
.styles-text-black,
.styles-hover-text-black:hover {
  color: #000 !important;
}
.styles-text-grey,
.styles-hover-text-grey:hover,
.styles-text-gray,
.styles-hover-text-gray:hover {
  color: #757575 !important;
}
.styles-text-light-grey,
.styles-hover-text-light-grey:hover,
.styles-text-light-gray,
.styles-hover-text-light-gray:hover {
  color: #f1f1f1 !important;
}
.styles-text-dark-grey,
.styles-hover-text-dark-grey:hover,
.styles-text-dark-gray,
.styles-hover-text-dark-gray:hover {
  color: #3a3a3a !important;
}
.styles-border-red,
.styles-hover-border-red:hover {
  border-color: #f44336 !important;
}
.styles-border-white,
.styles-hover-border-white:hover {
  border-color: #fff !important;
}
.styles-border-black,
.styles-hover-border-black:hover {
  border-color: #000 !important;
}
.styles-border-grey,
.styles-hover-border-grey:hover,
.styles-border-gray,
.styles-hover-border-gray:hover {
  border-color: #9e9e9e !important;
}
.styles-border-light-grey,
.styles-hover-border-light-grey:hover,
.styles-border-light-gray,
.styles-hover-border-light-gray:hover {
  border-color: #f1f1f1 !important;
}
.styles-border-dark-grey,
.styles-hover-border-dark-grey:hover,
.styles-border-dark-gray,
.styles-hover-border-dark-gray:hover {
  border-color: #616161 !important;
}
.styles-gold,
.styles-hover-gold:hover {
  color: #e0aa3e !important;
}
.styles-navy,
.styles-hover-navy:hover {
  color: #051336 !important;
}
.styles-navy-background,
.styles-hover-background-navy:hover {
  color: #000;
  background-color: #051336 !important;
}
.styles-navy-navBackground,
.styles-hover-background-navBackground:hover {
  color: #000;
  background-color: #051336;
}
.styles-gold-background,
.styles-hover-background-gold:hover {
  color: #000;
  background-color:#e0aa3e !important;
}
